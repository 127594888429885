import { ObjectValues } from '~/@types/generic'
import { createMetafieldInput } from '~/lib/shopify/utils/create-metafield-input'

import {
  MetafieldTypes,
  type UnlikelyMetafield,
} from '@unlikelystudio/commerce-connector'

export type Metafields = Record<string, string>

export const collectionMetafields: Metafields = {
  customizationItems: 'collection.customization_items',
  requiredCustomizationItems: 'collection.required_customization_items',
  labelCustomizations: 'collection.label_customizations',
  upsales: 'collection.upsale_collection',
  //@TODO reimplement satisfies once upgraded to next 13.x
} as const //satisfies Metafields

export const productVariantMetafields: Metafields = {
  atelierDate: 'atelier.date',
  legendCharlength: 'legend.charlength',
  defaultCustomizationTitle: 'variant.customization_default_title',
  isBundleProductPreOrder: 'variant.is_pre_order',
  disablesEngraving: 'variant.disables_engraving',
  isSubtitleEngraving: 'variant.is_engraving_with_subtitle',
  disablesLabel: 'variant.disables_label',
  isSubtitleLabel: 'variant.is_label_with_subtitle',
  isTitleLabel: 'variant.is_label_with_title',
  enablesSaucer: 'variant.enables_saucer',
  disabledForCandle: 'variant.not_available_for_candle_customization',
  variantInventoryIsTracked: 'variant.variant_inventory_is_not_tracked',
  //@TODO reimplement satisfies once upgraded to next 13.x
} as const //satisfies Metafields

export const productMetafields: Metafields = {
  isSimpleProductPreOrder: 'product.is_pre_order',
  //@TODO reimplement satisfies once upgraded to next 13.x
} as const //satisfies Metafields

export const productPackMetafields: Metafields = {
  packProducts1: 'product.pack_products_1',
  packProducts2: 'product.pack_products_2',
  packProducts3: 'product.pack_products_3',
  //@TODO reimplement satisfies once upgraded to next 13.x
} as const //satisfies Metafields

export const productRefillMetafields: Metafields = {
  refillProduct: 'extra.related_recharge_product',
}

export const customerMetafields: Metafields = {
  gender: 'customer.gender',
  birthday: 'customer.birthday',
  //@TODO reimplement satisfies once upgraded to next 13.x
} as const //satisfies Metafields

type CustomerMetafieldsKeys = ObjectValues<typeof customerMetafields>

type ProductVariantMetafieldsKeys = ObjectValues<
  typeof productVariantMetafields
>

export type MetafieldInput = UnlikelyMetafield & {
  processedKey: string
}

type MetafieldInputs = Record<string, Omit<MetafieldInput, 'ownerId' | 'value'>>

export const METAFIELD_DEFAULT_VALUE = 'unspecified'

export const METAFIELD_INPUTS: MetafieldInputs = {
  CUSTOMER_BIRTHDAY: createMetafieldInput<CustomerMetafieldsKeys>(
    'customer.birthday',
    MetafieldTypes.single_line_text_field,
  ),
  CUSTOMER_GENDER: createMetafieldInput<CustomerMetafieldsKeys>(
    'customer.gender',
    MetafieldTypes.single_line_text_field,
  ),
  //@TODO reimplement satisfies once upgraded to next 13.x
} //satisfies MetafieldInputs
