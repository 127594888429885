import { encode } from 'qss'
import { getStorePublicCredentials } from '~/lib/shopify/credentials/public'

interface ErrorPayload {
  code?: string
  field?: string[]
  message?: string
}
// declare class UnlikelyError extends Error {
//   payload: ErrorPayload[]
//   constructor(message: string, payload: ErrorPayload[])
// }

enum QueryActions {
  REGISTER = 'register',
  LOGIN = 'login',
  PROFILE = 'profile',
  ORDERS = 'orders',
  ADDRESSES = 'addresses',
  UPDATE_PASSWORD = 'update-password',
  REQUEST_RESET_PASSWORD = 'request-reset-password',
  RESET_PASSWORD = 'reset-password',
  ENABLE_ACCOUNT = 'enable-account',
  NEWSLETTER = 'newsletter',
  CONTACT = 'contact',
  RECAPTCHA = 'verify-recaptcha',
  GEOLOCATION = 'geolocation',
  UPSELLS = 'get-upsells',
  COUNTRIES_LIST = 'get-countries-list',
  UPSALES_BY_SKU = 'get-upsales-by-sku',
  POST_STOCK_ALERT = 'stock-alert/post',
  GET_PACK_TABS_PRODUCTS = 'multi-bundle/get-tab-products',

  // Preview
  PREVIEW = 'akairos/preview-text',
  GET_PREVIEW = 'akairos/previews/get',
  GET_NOTE_PREVIEW = 'akairos/note',
  POST_IMAGE_PREVIEW = 'akairos/image-preview',

  // SHIPMENT
  GET_SHIPMENT = 'akairos/shipment/get',

  // MULTIPASS
  MULTIPASS = 'multipass/post',

  // JUDGE ME
  JUDGE_ME_GET_PRODUCT_REVIEWS = 'reviews/get',
  JUDGE_ME_POST_REVIEW = 'reviews/post',
  JUDGE_ME_GET_CUSTOMER_PRODUCT_REVIEW_STATUS = 'customer-product-review',
  JUDGE_ME_REVIEWER = 'judgeme/reviewers/get',
}

export enum QueryMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface MetafieldInput {
  description?: string
  id?: string
  key?: string
  namespace?: string
  value?: string
  type?: string
}

export type QueryPayload = Record<
  string,
  string | object | boolean | MetafieldInput[]
>

export interface QueryParams {
  store?: string
  payload?: QueryPayload
  headers?: HeadersInit
  accessToken?: string

  queryParams?: Record<string, string>
}

export interface GenerateQueryParams extends QueryParams {
  action: QueryActions | string
  method: QueryMethods
}

interface EndpointSettings {
  action: QueryActions | string
  method: QueryMethods
}

async function generateQuery({
  store,
  action,
  payload,
  accessToken,
  queryParams = null,
  method,
}: GenerateQueryParams) {
  const hasBody = method !== QueryMethods.GET
  const shop = getStorePublicCredentials()

  const req = await fetch(
    `/api/${action}${queryParams ? `?${encode(queryParams)}` : ``}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-unlikely-store-url': shop?.storeUrl,
        'x-unlikely-store-token': shop?.storefrontAccessToken,
        authorization: accessToken,
      },
      method,
      ...(hasBody && { body: JSON.stringify(payload) }),
    },
  )

  const res = await req.json()

  if (req.ok === false) {
    throw new Error(res?.message ?? 'error')
  }
  //TODO: send relevant UnlikelyError (could be different than a Shopify error)
  // if (res.payload) throw new UnlikelyError('shopify', res.payload)

  return res
}

export const requestNextApiEndpoint = async (
  settings: EndpointSettings,
  params?: QueryParams,
) => {
  return await generateQuery({
    ...settings,
    ...(params ? params : {}),
  })
}

export async function register(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.REGISTER,
    method: QueryMethods.POST,
  })
}

export async function login(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.LOGIN,
    method: QueryMethods.POST,
  })
}

export async function getCustomer(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.PROFILE,
    accessToken: params.accessToken,
    method: QueryMethods.GET,
  })
}

export async function getOrders(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ORDERS,
    accessToken: params.accessToken,
    method: QueryMethods.GET,
  })
}

export async function getAddresses(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ADDRESSES,
    accessToken: params.accessToken,
    method: QueryMethods.GET,
  })
}

export async function getUpsalesBySku(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.UPSALES_BY_SKU,
    accessToken: params.accessToken,
    method: QueryMethods.POST,
  })
}

export async function updateCustomer(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.PROFILE,
    accessToken: params.accessToken,
    method: QueryMethods.PUT,
  })
}

export async function updateAddress(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ADDRESSES,
    accessToken: params.accessToken,
    method: QueryMethods.PUT,
  })
}

export async function updatePassword(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.UPDATE_PASSWORD,
    accessToken: params.accessToken,
    method: QueryMethods.PUT,
  })
}

export async function requestResetPassword(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.REQUEST_RESET_PASSWORD,
    method: QueryMethods.POST,
  })
}

export async function resetPassword(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.RESET_PASSWORD,
    method: QueryMethods.POST,
  })
}

export async function createAddress(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ADDRESSES,
    accessToken: params.accessToken,
    method: QueryMethods.POST,
  })
}

export async function deleteAddress(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ADDRESSES,
    accessToken: params.accessToken,
    method: QueryMethods.DELETE,
  })
}

export async function registerToNewsletter(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.NEWSLETTER,
    method: QueryMethods.POST,
  })
}

export async function enableAccount(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.ENABLE_ACCOUNT,
    method: QueryMethods.POST,
  })
}

export async function submitContactForm(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.CONTACT,
    method: QueryMethods.POST,
  })
}

export const verifyRecaptcha = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.RECAPTCHA,
    method: QueryMethods.POST,
  })
}

export const getGeolocation = async (params?: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.GEOLOCATION,
    method: QueryMethods.GET,
  })
}

export const getUpsells = async (params?: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.UPSELLS,
    method: QueryMethods.POST,
  })
}

export const getMultipassCheckoutUrl = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.MULTIPASS,
    method: QueryMethods.POST,
  })
}

export const getCountriesList = async (locale: string) => {
  return await generateQuery({
    action: `${QueryActions.COUNTRIES_LIST}`,
    method: QueryMethods.GET,
    queryParams: {
      locale,
    },
  })
}

export const postStockAlert = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.POST_STOCK_ALERT,
    method: QueryMethods.POST,
  })
}

export const postJudgemeReview = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.JUDGE_ME_POST_REVIEW,
    method: QueryMethods.POST,
  })
}

export const getCustomerProductReviewStatus = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.JUDGE_ME_GET_CUSTOMER_PRODUCT_REVIEW_STATUS,
    method: QueryMethods.GET,
  })
}

export const getJudgemeReviewer = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.JUDGE_ME_REVIEWER,
    method: QueryMethods.GET,
  })
}

export const getJudgemeReviews = async (params: {
  locale: string
  page: string
  totalPages: string
  productId: string
}) => {
  return await generateQuery({
    action: QueryActions.JUDGE_ME_GET_PRODUCT_REVIEWS,
    method: QueryMethods.GET,
    queryParams: {
      ...params,
    },
  })
}

export const postAkairosPreview = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.PREVIEW,
    method: QueryMethods.POST,
  })
}

export const postNotePreview = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.GET_NOTE_PREVIEW,
    method: QueryMethods.POST,
  })
}

export const postAkairosImagePreview = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.POST_IMAGE_PREVIEW,
    method: QueryMethods.POST,
  })
}

export const getAkairosPreviewImage = async (params: QueryParams) => {
  return await generateQuery({
    action: QueryActions.GET_PREVIEW,
    method: QueryMethods.GET,
    ...params,
  })
}
export const getAkairosShipments = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.GET_SHIPMENT,
    method: QueryMethods.POST,
  })
}

export const getPackTabProducts = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryActions.GET_PACK_TABS_PRODUCTS,
    method: QueryMethods.POST,
  })
}

export async function getMultipass(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryActions.MULTIPASS,
    accessToken: params.accessToken,
    method: QueryMethods.POST,
  })
}
