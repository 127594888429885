import processEnvPublic from 'unlikely-env/public'
import multistore from '~/../config/multistore.json'
import { FACETS } from '~/lib/algolia/constants'

import { uniq } from '~/utils/uniq'

export interface SingleRouteParams {
  type: string
  href: string
}
interface SingleRoutesList {
  [key: string]: SingleRouteParams
}

export const SINGLE_ROUTES: SingleRoutesList = {
  login: {
    type: 'login_page',
    href: '/login',
  },
  register: {
    type: 'register_page',
    href: '/register',
  },
  forgotPassword: {
    type: 'forgot_password_page',
    href: '/forgot-password',
  },
  resetPassword: {
    type: 'reset_password_page',
    href: '/reset-password',
  },
  enableAccount: {
    type: 'enable_account_page',
    href: '/enable-account',
  },
  account: {
    type: 'account_page',
    href: '/account',
  },
  search: {
    type: 'search',
    href: '/search',
  },
  blog: {
    type: 'blog',
    href: '/blog',
  },
}

export const isLocked = processEnvPublic('NEXT_PUBLIC_ENABLE_AUTH') === 'true'

export const LOCALES = Object.keys(multistore)
export const CURRENCIES =
  uniq(Object.values(multistore).map((entry) => entry.currency)) ?? []

export const COOKIE_KEYS = {
  recentlyViewed: 'recently-viewed-cookie',
  eventPopin: 'event-pop-in',
  checkoutId: 'shopify-checkout-id',
  customerAccessToken: 'customer-access-token',
  globalGeolocationPopIn: 'global-geolocation-pop-in',
  checkoutGeolocationPopIn: 'checkout-geolocation-pop-in',
  newsletterPopIn: (currency) => `newsletter-pop-in_${currency}`,
  newsletterPopInAttempts: (currency) =>
    `newsletter-pop-in_attempts_${currency}`,
}

// This constant needs to be set to true when going through busy periods like "Fête des Mères" / "Noël", etc.
// It will switch the API to the secondary one to avoid overloading the main one at the needed API calls.
export const AKAIROS_IS_BUSY_PERIOD = false

export const akairosApi = {
  MAIN: `api.bycharlot.akairos.fr`,
  SECONDARY: 'production-spare-api2.bycharlot.akairos.fr',
}

// This constant is to avoid getting trapped into the 404 placeholder if the page is a redirect and has no pageProps for example.
// See _app.tsx
export const PATHNAMES_EXCLUDED_FROM_FALLBACK_PLACEHOLDER = ['/redirects/[uid]']

export const SHOPIFY_CUSTOM_ATTRIBUTES = {
  name: '_name',
  slug: '_slug',
  image: '_image',
  shopifyId: '_shopifyId',
  category: '_category',
  variant: '_variant',
  productVariantId: '_productVariantId',
  unserializedPrice: '_unserializedPrice',
  trackingData: '_trackingData',
  tags: '_tags',
}

export const TRACKING_EVENTS = {
  ALERT_SUBSCRIPTION: 'alert_subscription',
  CHECKOUT_BEGIN: 'begin_checkout',
  CONTACT_FORM_SUBMIT: 'form_submitted',
  CTA_CLICKED: 'cta_clicked',
  LEAVE_REVIEW_CTA: 'cta_donner_avis',
  LOGIN: 'login',
  NEWSLETTER_SUBSCRIPTION: 'newsletter_subscription',
  OPEN_CART: 'view_cart',
  PAGE_VIEW: 'page_view',
  PRODUCT_ADD_TO_CART: 'add_to_cart',
  PRODUCT_ADD_TO_WISHLIST: 'add_to_wishlist',
  PRODUCT_CLICK: 'select_item',
  PRODUCT_DETAIL: 'view_item',
  VIEW_ITEM_RECENTLY: 'view_item_recently',
  PRODUCT_IMPRESSION_LIST: 'view_item_list',
  PRODUCT_REMOVE_FROM_CART: 'remove_from_cart',
  PROMOTION_CLICK: 'select_promotion',
  PROMOTION_VIEW: 'view_promotion',
  REVIEW_SUBMITTED: 'form_submitted',
  SEARCH: 'search',
  PRODUCT_FILTER: 'product_filter',
  SEARCH_CATEGORY_CLICK: 'click_search_category',
  SEARCH_RESULT_CLICKED: 'search_result_clicked',
  SIGN_UP: 'sign_up',
}

export const ALGOLIA_HITS_PER_PAGE = 24
export const ALGOLIA_PRODUCT_INDEX = 'horizon_product'

export const FACETS_TO_RETRIEVE = [
  FACETS.VERTUS,
  FACETS.COLORS,
  FACETS.SIGNIFICATIONS,
  FACETS.MATERIALS,
  FACETS.PLANT_TYPE,
  FACETS.PRODUCT_TYPE,
  FACETS.PLANT_SIZE,
  FACETS.BRAND,
  FACETS.PLANT_EXPOSURES,
  FACETS.PRODUCT_ENVIRONMENTS,
]

export const contactEmailSubject = 'Demande de renseignements'

export const DEFAULT_CURRENCY = 'eur'
export const SHOPIFY_API_VERSION = '2022-04'
export const RECAPTCHA_PUBLIC_API_KEY =
  '6Ld6rLsdAAAAAI1eQNN1qsp_48y4bdwfCk9PejIX'
export const DEFAULT_LOCALE = 'fr'
export const DEFAULT_LANG = 'fr'
export const DEFAULT_INTL = 'fr-FR'
export const PROJECT_NAME = 'bycharlot'

export const SIZE_VARIANT_KEY = 'Taille'
export const GTM_ID = 'GTM-MR2B456'

export const SHOPIFY_DOMAIN = '.myshopify.com'
export const STORE_URL = `charlot-co${SHOPIFY_DOMAIN}`
export const CHECKOUT_URL = 'checkout.bycharlot.com'

export const DOMAIN = 'bycharlot.com'

/*** CUSTOMIZER ***/
export const PRODUCT_TAGS = {
  CUSTOMIZE: 'Customize',
  PRE_ORDER: 'pre-order',
  PLANT: 'plant',
  POT: 'pot',
  CANDLE: 'candle',
  CANDLEV2: 'candlev2',
  SAUCER: 'saucer',
  FRAGRANCE: 'fragrance',
  LABEL: 'label',
  PUCK: 'puck',
  PACK: 'pack',
  INFUSION: 'infusion',
  HERBARIUM: 'herbarium',
  FERTILIZER: 'fertilizer',
  ENGRAVING: 'engraving',
  OOS_METAL: 'out-of-stock-metal',
  OOS_CERAMIC: 'out-of-stock-ceramic',
  REQUIRED: 'required',
  POMANDER: 'pomander',
  COVER: 'cover',
}

export const PRODUCT_SIZES = {
  XXS: 'XXS',
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
  XXL: 'XXL',
} as const

export const PRODUCT_TYPES = {
  PLANT: 'plant',
  CANDLE: 'candle',
  CANDLEV2: 'candlev2',
  FRAGRANCE: 'fragrance',
  LABEL: 'label',
  POT: 'pot',
  PUCK: 'puck',
  HERBARIUM: 'herbarium',
  FERTILIZER: 'fertilizer',
  SAUCER: 'saucer',
  INFUSION: 'infusion',
  ENGRAVING: 'engraving',
  DEFAULT: 'default',
  POMANDER: 'pomander',
  COVER: 'cover',
} as const

export type ProductType = (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES]

export const packsMetafields = [
  'pack_products_1',
  'pack_products_2',
  'pack_products_3',
]

export const CUSTOMIZATION_TAGS = {
  LABEL: 'label',
  LABEL_WITH_IMAGE: 'label_with_image',
  LEGEND: 'legend',
  ENGRAVING: 'engraving',
} as const

export const shopifyVariantDefaultTitle = 'Default Title'

export const DISABLE_VARIANTS_METAFIELDS_KEYS = {
  [PRODUCT_TYPES.CANDLE]: 'not_available_for_candle_customization',
}

export const JUDGEME = {
  enabled: processEnvPublic('NEXT_PUBLIC_DISABLE_JUDGEME') !== 'true',
  platform: 'shopify',
  DEFAULT_DISPLAYED_REVIEWS: 3,
  REVIEWS_PER_PAGE: 5,
  MAX_RATING: 5,
  VERIFIED: 'confirmed-buyer',
  CURATED: 'ok',
  BADGE_CLASSNAME: 'jdgm-prev-badge',
  REVIEWS_AVERAGE_DATA_ATTRIBUTE: 'data-average-rating',
}

export const QUERY_PARAMS = {
  CART: {
    key: 'cart',
    value: 'open',
  },
} as const
