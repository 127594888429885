import { GlobalGender } from '~/@types/gender'

export const GENERAL = {
  COOKIE_EDIT_CONSENT: 'general.cookie_edit_consent',
  BURGER_ARIA_LABEL: 'general.burger_aria_label',
  HOME_PAGE: 'general.homepage',
  ACCOUNT: 'general.account',
  SEARCH: 'general.search',
  LOGO_TITLE: 'general.logo_title',
  CATEGORY_PAGE: 'general.category_page',
  PRODUCT_PAGE: 'general.product_page',
  OPTIONAL: 'general.optional',
  BACK_HOME: 'general.back_home',
}

export const GENDER = {
  [GlobalGender.MALE]: 'gender.male',
  [GlobalGender.FEMALE]: 'gender.female',
  [GlobalGender.OTHER]: 'gender.other',
}

export const PRODUCT = {
  CUSTOMIZATION_INSTRUCTIONS: 'customization.instructions',
  CUSTOMIZATION_INSTRUCTIONS_TITLE: 'customization.instructions_title',
  CUSTOMIZATION_INSTRUCTIONS_TEXT: 'customization.instructions_text',
  CUSTOMIZATION_LEGEND: 'customization.legend',
  CUSTOMIZATION_LEGEND_TITLE: 'customization.legend_title',
  CUSTOMIZATION_LEGEND_TEXT: 'customization.legend_text',
  CUSTOMIZATION_DATE: 'customization.date',
  CUSTOMIZATION_DATE_TITLE: 'customization.date_title',
  CUSTOMIZATION_DATE_TEXT: 'customization.date_text',
  CUSTOMIZATION_CHOOSE_LETTER: 'product.customization.choose_letter',
  CUSTOMIZATION_CHOOSE_FIRST_LETTER:
    'product.customization.choose_first_letter',
  CUSTOMIZATION_CHOOSE_SECOND_LETTER:
    'product.customization.choose_second_letter',
  PRICE_FROM: 'product.price_from',
  VARIANT_ADD: 'product.variant_add',
  VARIANT_CHOOSE: 'product.variant_choose',
  SIZE: 'product.size',
  DELIVERY: 'product.delivery',
  FREE: 'product.free',
  RECOMMENDATIONS: 'product.recommendations',
  ADD_UPSALE: 'product.upsale_add',
  AMOUNT: 'product.amount',
  GIFT_VALUE: 'product.gift_value',
  CUSTOMIZATION: (type: string) => `product.customization_${type}`,
  REVIEWS: 'product.reviews',
  PREVIEW_BUNDLE: 'product.preview_bundle',
}

export const FAQ = {
  VIEW_ALL: 'faq.view_all',
}

export const FORM = {
  ACCEPT_MARKETING: 'form.accept_marketing',
  ADDRESS_NAME: 'form.address_name',
  BIRTH_DATE: 'form.birth_date',
  CATEGORY: 'form.category',
  CITY: 'form.city',
  CREATE_ADDRESS: 'form.create_address',
  CONFIRM_PASSWORD: 'form.confirm_password',
  COUNTRY: 'form.country',
  DEFAULT_ADDRESS: 'form.default_address',
  SAVE_PREFERENCES: 'form.save_preferences',
  EMAIL: 'form.email',
  FEMALE: 'form.female',
  FIRST_NAME: 'form.first_name',
  INFOS_ADDRESS: 'form.infos_address',
  LAST_NAME: 'form.last_name',
  MALE: 'form.male',
  MANDATORY: 'form.mandatory',
  MESSAGE: 'form.message',
  NEW_PASSWORD: 'form.new_password',
  NEWSLETTER_EMAIL: 'form.newsletter_email',
  NEWSLETTER_SUCCESS: 'form.newsletter_success',
  PASSWORD: 'form.password',
  PASSWORD_RESTRICTION: 'form.password_restriction',
  PRIVACY_POLICY: 'form.privacy_policy',
  REGION: 'form.region',
  RESET: 'form.reset',
  REPEAT_PASSWORD: 'form.repeat_password',
  REPEAT_NEW_PASSWORD: 'form.repeat_password',
  SAVE: 'form.save',
  SEARCH: 'form.search',
  SEND: 'form.send',
  STATE: 'form.state',
  STREET_NAME: 'form.street_name',
  SUBJECT: 'form.subject',
  SUBMIT: 'form.submit',
  TEL: 'form.tel',
  TITLE: 'form.title',
  ZIP_CODE: 'form.zip_code',
  TEXTAREA_PLACEHOLDER: 'form.textarea_placeholder',
  STAR: 'form.star',
  STARS: 'form.stars',
  REVIEW_TITLE: 'form.review_title',
  REVIEW_BODY: 'form.review_body',
  REVIEW_RATING: 'form.review_rating',
  FULL_NAME: 'form.full_name',
  OK: 'form.ok',
}

export const ACCOUNT = {
  DISCONNECT: 'account.disconnect',
  UPDATE: 'account.update',
  REMOVE: 'account.remove',
  USE_DEFAULT_ADDRESS: 'account.use_default_address',
  ADD_ADDRESS: 'account.add_address',
  DEFAULT_ADDRESS: 'account.default_address',
  FORGOT_PASSWORD: 'account.forgot_password',
  REGISTER: 'account.register',
}

export const CART = {
  PRE_ORDER: 'cart.pre_order',
  REMOVE_ITEM_TITLE: 'cart.remove_item_title',
  REMOVE_ITEM_TEXT: 'cart.remove_item_text',
  CART: 'cart',
  EMPTY: 'cart.empty',
  BEST_SELLERS: 'cart.best_sellers',
  ORDER: 'cart.order',
  DETAILS: 'cart.details',
  REDUCE_DETAILS: 'cart.reduce_details',
  PRODUCT: 'cart.product',
  GO_HOME: 'cart.go_home',
  PRODUCT_ADDED: 'cart.product_added',
  COMPLETE_UPSELLS: 'cart.complete_upsells',
  PRICE_TOTAL: 'cart.price_total',
  QUANTITY_SHORT: 'cart.quantity_short',
  INCREASE_QUANTITY: 'cart.increase_quantity',
  DECREASE_QUANTITY: 'cart.decrease_quantity',
  REMOVE_ITEM: 'cart.remove_item',
  SHIPPING_DISCOUNT: 'cart.shipping_discount',
  NOTE: 'cart.note',
  NOTE_ADDED: 'cart.note_added',
  NOTE_DELETED: 'cart.note_deleted',
}

export const NOTE = {
  ADD: 'note.add',
  ADDED: 'cart.note_added',
  MESSAGE_PLACEHOLDER: 'note.message_placeholder',
  SIGNATURE_PLACEHOLDER: 'note.signature_placeholder',
  TEXT: 'note.text',
}

export const CTA = {
  PRE_ORDER: 'cta.pre_order',
  ADD_TO_CART: 'cta.add_to_cart',
  CART: 'cta.cart',
  DELETE: 'cta.delete',
  CHARACTERS_LEFT: 'cta.characters_left',
  VALIDATE: 'cta.validate',
  REMOVE_FROM_CART: 'cta.remove',
  LEARN_MORE: 'cta.learn_more',
  CLOSE: 'cta.close',
  SEE_PRODUCT: 'cta.see_product',
  SHOW_ALL: 'cta.show_all',
  REDUCE: 'cta.reduce',
  READ_MORE: 'cta.read_more',
  READ_LESS: 'cta.read_less',
  QUICKBUY: 'cta.quickbuy',
  VIEW_MORE: 'cta.view_more',
  OUT_OF_STOCK: 'cta.out_of_stock',
  NEXT: 'cta.next',
  PREV: 'cta.prev',
  LOAD_MORE: 'cta.load_more',
  LOADING: 'cta.loading',
  BACK: 'cta.back',
  CANCEL: 'cta.cancel',
  COPIED: 'cta.copied',
  STOCK_ALERT: 'cta.stock_alert',
  COPY_ADDRESS: 'cta.copy_address',
  CONFIRM_CART: 'cta.confirm_cart',
  PERSONALIZE: 'cta.personalize',
  CONTINUE_SHOPPING: 'cta.continue_shopping',
}

export const POPIN = {
  NEWSLETTER: 'popin.newsletter',
}

export const PANEL = {
  CLOSE: 'panel.close',
  SEE_ALL: 'panel.see_all',
}

export const FILTER = {
  CLEAR_ALL: 'filter.clear_all',
  NO_FILTERS: 'filter.no_filters',
  COUNT_AVAILABILITY:
    '%{smart_count} filter.count_availability |||| %{smart_count} filter.count_availabilities',
  FILTER_BY: 'filter.filter_by',
  NO_RESULT: 'filter.no_result',
  SORT_BY: 'filter.sort_by',
  VALIDATE: 'filter.validate',
  REFINE: 'filter.refine',
  ORDER_BY_NEWEST: 'filter.order_by_newest',
  ORDER_BY_PRICE_ASC: 'filter.order_by_price_asc',
}

export const ERROR = {
  REQUIRED: 'error.required',
  TEL: 'error.tel',
  CODE: (code) => `error.${code.toLocaleLowerCase()}`,
}

export const SEARCH = {
  COUNT_AVAILABILITY: 'search.count_availability',
  EMPTY_RESULTS: 'search.search_no_results',
  LOAD_MORE: 'search.search_load_more',
  RESET: 'search.reset',
  RESULTS: 'search.results',
  BEST_SELLERS: 'search.best_sellers',
  SUGGESTIONS: 'search.suggestions',
  NO_RESULT: 'search.no_result',
}

export const ORDER = {
  /* Shopify order status */
  STATUS_UNFULFILLED: 'order.status_unfulfilled',
  STATUS_PARTIALLYFULFILLED: 'order.status_partially_fulfilled',
  STATUS_FULFILLED: 'order.status_fulfilled',
  STATUS_RESTOCKED: 'order.status_restocked',
  STATUS_PENDINGFULFILLMENT: 'order.status_pending_fulfillment',
  STATUS_OPEN: 'order.status_open',
  STATUS_INPROGRESS: 'order.status_in_progress',
  STATUS_ONHOLD: 'order.status_on_hold',
  STATUS_SCHEDULED: 'order.status_scheduled',
  ORDER_TITLE: 'order.order_title',
  TRACKING_TEXT: 'order.tracking_text',
  FOLLOW_ORDER: 'order.follow_order',
  DELIVERY_ADDRESS: 'order.delivery_address',
  DOWNLOAD_INVOICE: 'order.download_invoice',
  QUANTITY: 'order.quantity',
  SEE_DETAILS: 'order.see_details',
  DATE: 'order.date',
  TOTAL: 'order.total',
  STATUS: 'order.status',
  PRODUCTS: 'order.products',
  SEE_MORE: 'order.see_more',
}

export const ALERT = {
  ERROR_TITLE: 'alert.error_title',
  SUCCESS_TITLE: 'alert.success_title',
  ADDRESS_CREATED: 'alert.address_created',
  ENTITY_UPDATED: 'alert.entity_updated',
  ACCOUNT_CREATED: 'alert.account_created',
  QUANTITY_CORRECTED: 'alert.quantity_corrected',
  RESET_PASSWORD_EMAIL_SENT: 'alert.reset_password_email_sent',
  CUSTOMIZATION_ERROR: 'alert.customization_error',
}

export const LANG_SWITCHER = {
  LANGUAGE: (lang = 'en') => `language.${lang?.toLocaleLowerCase()}`,
  LANGUAGE_SYMBOL: (lang = 'en') =>
    `language_symbol.${lang?.toLocaleLowerCase()}`,
}

export const BREADCRUMB = {}

export const TAG = {
  CUSTOMIZABLE: 'tag.customizable',
  PRE_ORDER: 'tag.pre_order',
  IN_STOCK: 'tag.in_stock',
  NOT_IN_STOCK: 'cta.out_of_stock',
  ALERT_AVAILABLE: 'cta.alert_available',
  DELIVERED_IN: 'tag.delivered_in',
}

export const GIFT = {
  UNSELECT: 'gift.unselect',
  SELECT: 'gift.select',
  NO_GIFT: 'gift.no_gift',
  FREE: 'gift.free',
}

export const CUSTOMIZER = {
  CUSTOMIZE_LABEL: 'customizer.customize_label',
  ADD_PRODUCT_NOTE: 'customizer.add_product_note',
  COMPLETE_WITH: 'customizer.complete_with',
  NONE: 'customizer.none',
  LABEL_TITLE: 'customizer.label_title',
  LABEL_SUBTITLE: 'customizer.label_subtitle',
  FILE_TOO_LARGE: 'customizer.file_too_large',
  PERSONALIZATION: 'customizer.personalization',
  FIELD_TOO_LONG: 'cutomizer.field_too_long',
  NEXT_PRODUCT: 'cutomizer.next_product',
  VALIDATE: 'customizer.validate',
  CUSTOMIZE_MULTI_BUNDLE_ITEM: `customizer.customize_multi_bundle_item`,
  CLOSE_MULTI_BUNDLE_ITEM: `customizer.close_multi_bundle_item`,
  BUNDLE_SELECT: `customizer.bundle_select`,
  SELECT_VARIANT: `customizer.select_variant`,
  ADD_PRODUCT: (type) => `customizer.add_${type}`,
  SELECT_PRODUCT: (type) => `customizer.select_${type}`,
  EDIT_PRODUCT: (type) => `customizer.edit_${type}`,
  PERSONALIZE_PRODUCT: (type) => `customizer.personalize_${type}`,
  NO_TYPE: (type) => `customizer.none_${type}`,
}

export const BUTTON = {
  BACK_TO_TOP: 'button.back_to_top',
  CLOSE: 'button.close',
  NAVIGATE_PREV: 'button.navigate_prev',
  NAVIGATE_NEXT: 'button.navigate_next',
  OPEN_MENU: 'button.open_menu',
  OPEN_CART: 'button.open_cart',
  CLOSE_MENU: 'button.close_menu',
  LAYOUT_SWITCHER: (layout) => `button.layout_switcher_${layout}`,
}

export const DATE = {
  TODAY: 'date.today',
  TOMORROW: 'date.tomorrow',
  HOURS: (hours) => (hours > 1 ? `date.hours` : `date.hour`),
  MINUTES: (minutes) => (minutes > 1 ? `date.minutes` : `date.minute`),
}

export const SHIPMENT = {
  CUTOFF: 'shipment.cutoff',
  INFORMATIONS: 'shipment.informations',
  INFORMATIONS_POST_CUTOFF: 'shipment.informations_post_cutoff',
  ON_DATE: 'shipment.on_date',
  BETWEEN_DATES: 'shipment.between_dates',
}

export const PRODUCTS_TYPES = {
  LABEL: 'product_types.label',
  TYPE: (type) => `product_types.types.${type}`,
}

export const CUSTOM_ATTRIBUTES = {
  MESSAGE_FROM: 'custom_attributes.message_from',
  MESSAGE: 'custom_attributes.message',
  LABEL_TEXT: 'custom_attributes.label_text',
  LABEL_TITLE: 'custom_attributes.label_title',
}

export const REVIEWS = {
  VERIFIED: 'reviews.verified',
  COMMENTS: 'reviews.comments',
  LEAVE_REVIEW: 'reviews.leave_review',
  TITLE: 'reviews.title',
  NOTE: 'reviews.note',
  REVIEW_SENT: 'reviews.review_sent',
  ACCORDING_TO_SINGLE_REVIEW: 'reviews.according_to_review',
  ACCORDING_TO_MULTIPLE_REVIEWS: 'reviews.according_to_reviews',
}

export const SLIDER = {
  NEXT: 'slider.next',
  PREVIOUS: 'slider.previous',
}

// Debug to log every keys
// console.log(
//   JSON.stringify(
//     [
//       GENERAL,
//       GENDER,
//       PRODUCT,
//       FORM,
//       ACCOUNT,
//       CTA,
//       PANEL,
//       FILTER,
//       ERROR,
//       ORDER,
//       ALERT,
//       LANG_SWITCHER,
//       BREADCRUMB,
//     ]
//       ?.map((item) => Object.values(item))
//       .flat(2)
//       ?.map((item) => (typeof item === 'function' ? item?.() : item)),
//     null,
//     1,
//   ),
// )
